<template>
  <v-app>
    <v-container fill-height fluid>
      <v-layout align-center justify-center>
        <v-card flat class="px-3">
          <v-img class="mb-2" height="100px" contain :src="logo"></v-img>

          <v-radio-group v-show="step == 1" dense v-model="accountType" row>
            <v-radio
              :color="layout.topBarColor"
              label="Funcionário"
              value="employee"
            ></v-radio>
            <v-radio
              :color="layout.topBarColor"
              label="Estabelecimento"
              value="store"
            ></v-radio>
          </v-radio-group>

          <v-select
            v-show="step == 1 && accountType == 'store'"
            :color="layout.topBarColor"
            label="Tipo"
            v-model="documentType"
            :items="documentTypeItems"
          ></v-select>

          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field
              v-show="step == 1"
              v-model="document"
              :label="documentLabel"
              :color="layout.topBarColor"
              :rules="[documentRules]"
              v-mask="documentMask"
              clearable
            ></v-text-field>
            <v-text-field
              v-show="step == 1 && accountType == 'employee'"
              v-model="birthDate"
              label="Data de nascimento"
              :color="layout.topBarColor"
              :rules="[userRules]"
              v-mask="birthDateMask"
              clearable
            ></v-text-field>
            <v-text-field
              v-show="step == 1 && accountType == 'employee'"
              v-model="phone"
              label="Telefone"
              :color="layout.topBarColor"
              :rules="[userRules]"
              v-mask="phoneMask"
              clearable
            ></v-text-field>
            <v-text-field
              v-show="step == 2"
              v-model="name"
              label="Nome"
              :color="layout.topBarColor"
              disabled
            ></v-text-field>

            <!-- ADDRESS -->
            <v-text-field
              v-show="step == 2 && accountType == 'employee'"
              v-model="address"
              label="Endereço"
              :color="layout.topBarColor"
              :rules="[addressRules]"
              clearable
              dense
            ></v-text-field>
            <v-row
              align="center"
              v-show="step == 2 && accountType == 'employee'"
            >
              <v-col cols="6">
                <v-text-field
                  v-model="number"
                  label="Número"
                  :color="layout.topBarColor"
                  clearable
                  dense
                  v-mask="numberMask"
                  :rules="[addressNumberRules]"
                  :disabled="disableNumber"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-checkbox
                  v-model="disableNumber"
                  dense
                  :color="layout.topBarColor"
                  label="Sem número"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-text-field
              v-show="step == 2 && accountType == 'employee'"
              v-model="district"
              label="Bairro"
              :color="layout.topBarColor"
              :rules="[addressRules]"
              clearable
              dense
            ></v-text-field>
            <v-text-field
              v-show="step == 2 && accountType == 'employee'"
              v-model="city"
              label="Cidade"
              :color="layout.topBarColor"
              :rules="[addressRules]"
              clearable
              dense
            ></v-text-field>
            <v-text-field
              v-show="step == 2 && accountType == 'employee'"
              v-model="state"
              label="Estado"
              :color="layout.topBarColor"
              :rules="[stateRules]"
              v-mask="stateMask"
              clearable
              dense
            ></v-text-field>
            <v-text-field
              v-show="step == 2 && accountType == 'employee'"
              v-model="cep"
              label="CEP"
              :color="layout.topBarColor"
              :rules="[cepRules]"
              v-mask="cepMask"
              clearable
              dense
            ></v-text-field>
            <!-- ADDRESS -->

            <v-text-field
              v-show="step == 2 && accountType == 'employee'"
              v-model="email"
              label="Email"
              :color="layout.topBarColor"
              :rules="[emailRules]"
              clearable
              dense
            ></v-text-field>

            <!-- PASSWORD -->
            <v-text-field
              v-show="step == 2"
              v-model="password"
              label="Senha"
              :color="layout.topBarColor"
              type="password"
              autocomplete="off"
              autocorrect="off"
              autocapitalize="off"
              clearable
              dense
            ></v-text-field>
            <v-text-field
              v-show="step == 2"
              v-model="passwordConfirmation"
              label="Confirme a senha"
              :color="layout.topBarColor"
              type="password"
              autocomplete="off"
              autocorrect="off"
              autocapitalize="off"
              :rules="[passwordRules]"
              clearable
              dense
            ></v-text-field>
            <!-- PASSWORD -->
          </v-form>
          <v-alert
            class="mt-3"
            v-model="alert.status"
            outlined
            :type="alert.type"
            text
            :dismissible="alert.dismissible"
            transition="scale-transition"
          >
            {{ alert.message }}
          </v-alert>
          <v-btn
            v-show="step != 3"
            class="mt-3"
            dark
            block
            :color="layout.topBarColor"
            @click="process()"
            :disabled="loading"
            >{{ buttonLabel }}</v-btn
          >
        </v-card>
      </v-layout>
    </v-container>
    <loading :loading="loading" />
  </v-app>
</template>

<script>
import Loading from "./global/loading";

import axios from "axios";
import { apiURL } from "../main";
import { mask } from "vue-the-mask";

export default {
  components: {
    Loading,
  },
  directives: {
    mask,
  },
  data: () => ({
    loading: false,
    logo: null,
    layout: {},
    headers: {
      "content-type": "application/json",
    },
    merchant: null,
    idApp: null,
    accountType: "employee",
    documentTypeItems: [
      {
        text: "Pessoa Jurídica",
        value: "CNPJ",
      },
      {
        text: "Pessoa Física",
        value: "CPF",
      },
    ],
    documentType: "CPF",
    document: null,
    name: null,
    birthDate: null,
    birthDateMask: "##/##/####",
    phone: null,
    phoneMask: "(##) #####-####",
    password: null,
    passwordConfirmation: null,
    address: null,
    number: null,
    numberMask: "#########",
    disableNumber: false,
    district: null,
    city: null,
    state: null,
    stateMask: "AA",
    cep: null,
    cepMask: "#####-###",
    email: null,
    valid: true,
    step: 1,
    urlCode: null,
    alert: { status: false },
  }),
  computed: {
    documentLabel: function () {
      return this.documentType == "CPF" ? "CPF" : "CNPJ";
    },
    documentMask: function () {
      return this.documentType == "CPF"
        ? "###.###.###-##"
        : "##.###.###/####-##";
    },
    buttonLabel: function () {
      if (this.step == 1) return "enviar";
      else if (this.step == 3) return "acessar";
      else return "cadastrar";
    },
  },
  watch: {
    $route() {
      this.refresh();
    },
    accountType(value) {
      if (value == "employee") this.documentType = "CPF";
      else this.documentType = "CNPJ";
    },
  },
  methods: {
    documentRules: function (value) {
      if (this.step == 1) {
        if (!value || value == "") return "Campo obrigatório!";
        else if (this.documentType == "CPF" && value.length < 14)
          return "CPF inválido!";
        else if (this.documentType == "CNPJ" && value.length < 18)
          return "CNPJ inválido!";
        else return true;
      } else return true;
    },
    userRules: function (value) {
      if (
        this.step == 1 &&
        this.accountType == "employee" &&
        (!value || value == "")
      )
        return "Campo obrigatório!";
      else return true;
    },
    addressRules: function (value) {
      if (this.step == 2 && this.accountType == "employee") {
        if (!value || value == "") return "Campo obrigatório!";
        else if (value.length < 4) return "Endereço inválido!";
        else return true;
      } else return true;
    },
    addressNumberRules: function (value) {
      if (
        this.step == 2 &&
        this.accountType == "employee" &&
        !this.disableNumber
      ) {
        if (!value || value == "") return "Campo obrigatório!";
        else return true;
      } else return true;
    },
    stateRules: function (value) {
      if (this.step == 2 && this.accountType == "employee") {
        if (!value || value == "") return "Campo obrigatório!";
        else if (value.length < 2) return "Estado inválido!";
        else return true;
      } else return true;
    },
    cepRules: function (value) {
      if (this.step == 2 && this.accountType == "employee") {
        if (!value || value == "") return "Campo obrigatório!";
        else if (value.length < 9) return "CEP inválido!";
        else return true;
      } else return true;
    },
    emailRules: function (value) {
      if (this.step == 2 && this.accountType == "employee") {
        if (!value || value == "") return "Campo obrigatório!";
        else if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value))
          return "Email inválido!";
        else return true;
      } else return true;
    },
    passwordRules: function (value) {
      if (this.step == 2) {
        if (!this.password || this.password.length < 6)
          return "A senha deve conter pelo menos 6 caracteres!";
        if (value != this.password) return "As senhas não correspondem!";
        else return true;
      } else return true;
    },
    process: function () {
      if (this.step == 2) this.getAccount(true);
      else if (this.step == 3) this.$router.push("/login/" + this.idApp);
      else this.getAccount();
    },
    sendAlert: function (status, message, type, dismissible = false) {
      this.alert = {
        status: status,
        message: message,
        type: type,
        dismissible: dismissible,
      };
    },
    refresh: function () {
      if ("id-app" in this.$route.query)
        this.idApp = decodeURIComponent(this.$route.query["id-app"]);

      this.merchant = localStorage.getItem("user-account");
      if (this.merchant && this.merchant.length > 3) {
        if (!this.idApp) this.idApp = localStorage.getItem("login-merchant");
        this.logo = localStorage.getItem("logo");
        this.layout = JSON.parse(localStorage.getItem("layout-info")) || {};
      } else this.getMerchantInfo();

      this.urlCode = decodeURIComponent(this.$route.params.code);
      if (this.urlCode && this.urlCode != "undefined") {
        this.step = 2;
        this.getAccount();
      } else this.urlCode = null;
    },
    getMerchantInfo: function () {
      this.loading = true;
      this.merchant = this.$route.path.split("/")[1];

      let self = this;
      axios({
        method: "get",
        url: apiURL + "merchant/" + self.idApp,
        headers: this.headers,
      })
        .then(function (response) {
          if (response.data.error) console.log(response.data.message);
          else {
            let data = response.data.merchants[0];
            self.logo = data.logo;
            self.layout = JSON.parse(data.layout_info);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getAccount: async function (finish = false) {
      if (this.step == 1 || finish) {
        await this.$refs.form.validate();
        if (!this.valid) return;
      }

      this.loading = true;
      let headers = {
        "content-type": "application/json",
      };

      if (finish) this.step = 3;

      let payload = {
        url: window.location.origin,
        code: this.urlCode,
        document: this.document,
        id_app: this.idApp,
        step: this.step,
        account_type: this.accountType,
        birth_date: this.birthDate,
        phone: "55" + this.phone,
        password: this.password,
        address: this.address + " " + (this.number ? this.number : "S/N"),
        district: this.district,
        city: this.city,
        state: this.state,
        cep: this.cep,
        email: this.email,
      };

      let self = this;
      axios({
        method: "post",
        url: apiURL + "_public_/_run_/town_register?_account_=" + this.merchant,
        headers: headers,
        data: payload,
      })
        .then(function (response) {
          if (response.data.error) console.log(response.data.message);
          else {
            response = JSON.parse(response.data.py);

            if (response.status == false) {
              let message = "Link incorreto ou expirado!";
              if (self.step == 1) message = "Dados não encontrados!";
              if (response.code == "11")
                message = "Erro ao enviar o link de acesso!";
              self.sendAlert(true, message, "error", true);
            } else if (self.step == 2) {
              self.name = response.name;
              self.accountType = response.account_type;
            } else {
              let message =
                "Uma mensagem com o link para alteração da senha foi enviada para o seu celular!";
              if (self.step == 1) {
                self.step = 3;
                localStorage.setItem("registered", "true");
              } else if (self.step == 3)
                message = "Dados alterados com sucesso!";

              self.sendAlert(true, message, "success");
            }
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.refresh();
  },
};
</script>
