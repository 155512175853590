<template>
  <v-app>
    <top-menu :menuItems="topMenu" />

    <v-main>
      <v-card elevation="0" class="pa-2">
        <v-alert
          class="mt-3"
          v-model="alert.status"
          outlined
          :type="alert.type"
          text
          :dismissible="alert.dismissible"
          transition="scale-transition"
        >
          {{ alert.message }}
        </v-alert>
        <v-card-title class="text-h5 font-weight-medium"
          >Pagamentos pendentes</v-card-title
        >
        <v-card
          class="py-4 ma-4"
          v-if="!payments"
          flat
          color="grey lighten-4"
          height="auto"
        >
          <v-card-text class="text-h5 text-center grey--text">
            Nenhum pagamento pendente
          </v-card-text>
        </v-card>
        <v-expansion-panels popout v-if="payments" v-model="itemsOpened">
          <v-expansion-panel v-for="(item, index) in payments" :key="index">
            <v-expansion-panel-header class="text-h6">{{
              item.store
            }}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-divider class="mb-4"></v-divider>
              <p class="ma-0 text-caption">Requisitado em: {{ item.date }}</p>
              <p class="ma-0 text-caption">Telefone: {{ item.phone }}</p>
              <p class="ma-0 text-caption">Endereço: {{ item.address }}</p>
              <p
                class="mt-2 text-h4 grey--text text--darken-1 font-weight-medium"
              >
                R$ {{ item.amount }}
              </p>
              <v-text-field
                v-show="item.id_transacao_andamento == activeItem"
                v-model="password"
                :color="layout.topBarColor"
                label="Digite a senha para confirmação do pagamento"
                placeholder="Digite a sua senha de acesso"
                type="password"
                :rules="[passwordRules]"
              ></v-text-field>
              <v-btn
                v-show="item.id_transacao_andamento == activeItem"
                class="mt-0 mr-3 white--text"
                color="grey darken-1"
                @click="cancelPayment()"
                >cancelar</v-btn
              >
              <v-btn
                class="mt-0 white--text"
                color="red"
                @click="payButton(item)"
                :disabled="item.id_transacao_andamento == activeItem && !valid"
                :block="item.id_transacao_andamento != activeItem"
                >{{ getButtonLabel(item) }}</v-btn
              >
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
      <v-fab-transition>
        <v-btn
          color="success"
          fab
          dark
          bottom
          right
          absolute
          class="mb-12"
          @click="loadPayments()"
        >
          <v-icon>refresh</v-icon>
        </v-btn>
      </v-fab-transition>
    </v-main>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <bottom-menu account-type="employee" />
  </v-app>
</template>

<script>
import axios from "axios";
import { apiURL } from "../../main";
import TopMenu from "../menus/top-menu";
import BottomMenu from "../menus/bottom-menu";

export default {
  components: {
    TopMenu,
    BottomMenu,
  },
  data() {
    return {
      userToken: "",
      userId: "",
      payments: [],
      topMenu: {
        title: "",
      },
      itemsOpened: [],
      loading: false,
      valid: false,
      activeItem: null,
      password: null,
      alert: { status: false },
    };
  },
  methods: {
    passwordRules: function (value) {
      if (!value) return true;
      else if (value.length < 4) return "Senha inválida!";
      else {
        this.valid = true;
        return true;
      }
    },
    getButtonLabel: function (item) {
      if (item.id_transacao_andamento == this.activeItem) return "confirmar";
      else return "pagar";
    },
    payButton: function (item) {
      if (!this.activeItem) {
        this.valid = false;
        this.activeItem = item.id_transacao_andamento;
      } else this.pay(item);
    },
    cancelPayment: function () {
      this.password = null;
      this.valid = false;
      this.activeItem = null;
    },
    sendAlert: function (status, message, type, dismissible = true) {
      this.alert = {
        status: status,
        message: message,
        type: type,
        dismissible: dismissible,
      };
    },
    pay: function (data) {
      this.loading = true;

      let headers = {
        Authorization: "Bearer " + this.userToken,
        "content-type": "application/json",
      };

      let payload = {
        id_user: this.userId,
        id_trans: data.id_transacao_andamento,
        passwd: this.password,
      };

      let self = this;
      axios({
        method: "post",
        url: apiURL + "_run_/town_payment",
        headers: headers,
        data: payload,
      })
        .then(function (response) {
          if (response.data.error) console.log(response.data.message);
          else {
            response = JSON.parse(response.data.py);
            if (response.error == 0) {
              self.sendAlert(
                true,
                "Transação efetuada com sucesso!",
                "success"
              );
              self.cancelPayment();
              self.loadPayments();
            } else {
              self.loading = false;
              self.sendAlert(true, "Senha incorreta!", "error");
              console.error(response.message);
            }
          }
        })
        .catch((error) => {
          self.loading = false;
          console.error(error);
        });
    },
    loadPayments: function () {
      this.loading = true;
      let headers = {
        Authorization: "Bearer " + this.userToken,
        "content-type": "application/json",
      };

      let self = this;
      axios({
        method: "get",
        url: apiURL + "pending_payments",
        headers: headers,
      })
        .then(function (response) {
          if (response.data.error) console.log(response.data.message);
          else {
            self.payments = response.data.pending_payments;
            if (self.payments) {
              self.payments.map((item) => {
                item.date = new Date(item.date).toLocaleString("pt-BR", {
                  timeZone: "America/Sao_Paulo",
                });
                return item;
              });
              if (self.payments.length == 1) self.itemsOpened = [0];
            }
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          self.loading = false;
        });
    },
    refresh: function () {
      this.payments = [];
      this.topMenu.title = localStorage.getItem("system-name") || "";
      this.userToken = localStorage.getItem("user-token") || "";
      this.userId = localStorage.getItem("user-id") || "";

      let layout = localStorage.getItem("layout-info");
      this.layout = layout ? JSON.parse(layout) : {};

      this.loadPayments();
    },
  },
  watch: {
    $route() {
      this.refresh();
    },
    itemsOpened() {
      this.cancelPayment();
    },
  },
  mounted() {
    this.refresh();
  },
};
</script>
