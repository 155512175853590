import Vue from 'vue'
import App from './App.vue'
import vueRouter from 'vue-router'
import Login from './components/login.vue'
import vuetify from './plugins/vuetify'
import { VTextField } from 'vuetify/lib'
import VCurrencyField from "v-currency-field";
import * as VueGoogleMaps from "vue2-google-maps";
import 'material-design-icons-iconfont/dist/material-design-icons.css'

import ebsMain from "./components/ebs-main.vue"
import ebsLocations from "./components/ebs-locations.vue"
import ebsTransactions from "./components/ebs-transactions.vue"
import ebsPayment from "./components/client/ebs-payment.vue"
import ebsLend from "./components/client/ebs-lend.vue"
import ebsCharge from "./components/merchant/ebs-charge.vue"
import ebsRegister from "./components/ebs-register.vue"

export const apiURL = "/api/"
// export const apiURL = "http://localhost:8009/api/"
// export const apiURL = "https://ibanco.ebsbank.com.br/api/"

Vue.config.productionTip = false
Vue.use(VueGoogleMaps)

Vue.component('v-text-field', VTextField)
Vue.use(VCurrencyField, {
  locale: 'pt-BR',
  decimalLength: 2,
  autoDecimalMode: true,
  min: null,
  max: null,
  defaultValue: 0
})

Vue.use(vueRouter)

const routes = [
  {
    name: 'login',
    path: '/login/:account/:equip?',
    component: Login
  },
  {
    name: 'ebs-main',
    path: '/ebs-prefeitura/ebs-main',
    component: ebsMain
  },
  {
    name: 'ebs-locations',
    path: '/ebs-prefeitura/ebs-locations',
    component: ebsLocations
  },
  {
    name: 'ebs-transactions',
    path: '/ebs-prefeitura/ebs-transactions/:merchant?',
    component: ebsTransactions
  },
  {
    name: 'ebs-payment',
    path: '/ebs-prefeitura/ebs-payment',
    component: ebsPayment
  },
  {
    name: 'ebs-lend',
    path: '/ebs-prefeitura/ebs-lend',
    component: ebsLend
  },
  {
    name: 'ebs-charge',
    path: '/ebs-prefeitura/ebs-charge',
    component: ebsCharge
  },
  {
    name: 'ebs-register',
    path: '/ebs-prefeitura/ebs-register/:code?',
    component: ebsRegister
  },
  {
    name: 'ebs-landing',
    path: '/ebs-prefeitura/ebs-landing',
    redirect: '/login/ebs-aguabranca',
  }
]

const router = new vueRouter({
  routes
})

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
