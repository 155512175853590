<template>
  <v-app>
    <top-menu :menuItems="topMenu" />

    <v-main>
      <v-card elevation="0" class="ma-3">
        <v-card-title class="text-h5 font-weight-medium">
          Histórico de Pagamentos
        </v-card-title>
        <v-card
          class="py-4 mx-4"
          v-if="!transactions.length"
          flat
          color="grey lighten-4"
          height="auto"
        >
          <v-card-text class="text-h5 text-center grey--text">
            Histórico vazio
          </v-card-text>
        </v-card>
        <v-card v-if="transactions.length">
          <v-timeline align-top dense>
            <v-timeline-item
              small
              v-for="(item, i) in transactions"
              :key="i"
              :color="item.color"
            >
              <v-row class="pt-1" @click="timelineLink(item)">
                <v-col cols="4">
                  <strong>R$ {{ item.value }}</strong>
                </v-col>
                <v-col>
                  <strong>{{ item.name }}</strong>
                  <div class="text-caption">{{ item.date }}</div>
                </v-col>
              </v-row>
            </v-timeline-item>
          </v-timeline>
        </v-card>
      </v-card>
    </v-main>

    <bottom-menu :account-type="accountType" />
  </v-app>
</template>

<script>
import axios from "axios";
import { apiURL } from "../main";
import TopMenu from "./menus/top-menu";
import BottomMenu from "./menus/bottom-menu";

export default {
  components: {
    TopMenu,
    BottomMenu,
  },
  data() {
    return {
      userToken: "",
      transactions: [],
      accountType: "employee",
      topMenu: {
        title: "",
      },
    };
  },
  methods: {
    timelineLink: function (item) {
      if (this.accountType == "employee" && item.aproved == "0")
        this.$router.push("/ebs-prefeitura/ebs-payment");
    },
    getPayments: function () {
      let headers = {
        Authorization: "Bearer " + this.userToken,
        "content-type": "application/json",
      };

      let url = apiURL + "user_transactions";
      if (this.accountType == "store") url = apiURL + "merchant_transactions";

      let self = this;
      axios({
        method: "get",
        url: url,
        headers: headers,
      })
        .then(function (response) {
          if (response.data.error) {
            console.log(response.data.message);
          } else {
            let data = [];
            if (self.accountType == "store")
              data = response.data.merchant_transactions;
            else data = response.data.user_transactions;

            data.map((item) => {
              item["color"] = item.aproved == "1" ? "green" : "red";
              item.date = new Date(item.date).toLocaleString("pt-BR", {
                timeZone: "America/Sao_Paulo",
              });
              return item;
            });
            self.transactions = data.slice(0, 30);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    refresh: function () {
      this.userToken = localStorage.getItem("user-token") || "";
      this.topMenu.title = localStorage.getItem("system-name") || "";
      this.accountType = localStorage.getItem("account-type");

      this.getPayments();
    },
  },
  watch: {
    $route() {
      this.refresh();
    },
  },
  mounted() {
    this.refresh();
  },
};
</script>
