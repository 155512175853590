<template>
  <v-bottom-navigation app fixed>
    <v-card width="20px" class="mt-4 mr-1 swiper-btn-prev" flat>
      <v-icon>mdi-chevron-left</v-icon>
    </v-card>
    <swiper :options="swiperOptions">
      <swiper-slide v-for="(item, i) in menuItems" :key="i" class="swiper">
        <v-btn :key="i" v-if="item.active" color="grey lighten-3" class="px-0">
          <span class="grey--text text--darken-2">{{ item.label }}</span>
          <v-icon color="grey darken-2">{{ item.icon }}</v-icon>
        </v-btn>

        <v-btn
          :key="i"
          v-if="!item.active && !('dialog' in item)"
          :to="'link' in item ? item.link : null"
          class="px-0"
        >
          <span>{{ item.label }}</span>
          <v-icon>{{ item.icon }}</v-icon>
        </v-btn>

        <v-tooltip
          v-if="'dialog' in item"
          v-model="showTooltip"
          top
          :color="tooltipType"
        >
          <template v-slot:activator="{}">
            <v-btn
              :key="i"
              v-if="!item.active"
              :to="'link' in item ? item.link : null"
              @click="'dialog' in item ? triggerDialog(item.dialog) : null"
              class="px-0"
              :disabled="loading"
            >
              <span>{{ item.label }}</span>
              <v-icon>{{ item.icon }}</v-icon>
            </v-btn>
          </template>
          <span>{{ tooltipMsg }}</span>
        </v-tooltip>
      </swiper-slide>
    </swiper>
    <v-card width="20px" class="mt-4 mr-1 swiper-btn-next" flat>
      <v-icon>mdi-chevron-right</v-icon>
    </v-card>
  </v-bottom-navigation>
</template>

<script>
import axios from "axios";
import { apiURL } from "../../main";

import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.min.css";

export default {
  props: {
    accountType: String,
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: 4,
        freeMode: true,
        navigation: {
          prevEl: ".swiper-btn-prev",
          nextEl: ".swiper-btn-next",
        },
      },
      showTooltip: false,
      tooltipType: "",
      tooltipMsg: "",
      loading: false,
    };
  },
  watch: {
    showTooltip(value) {
      let self = this;
      if (value) {
        setTimeout(function () {
          self.showTooltip = false;
        }, 10000);
      }
    },
  },
  methods: {
    triggerDialog: function (dialog) {
      if (this.loading || this.showTooltip) return;

      this.loading = true;

      let headers = {
        Authorization: "Bearer " + localStorage.getItem("user-token"),
        "content-type": "application/json",
      };

      let payload = {
        user_id: localStorage.getItem("user-id"),
        dialog: dialog,
      };

      let self = this;
      axios({
        method: "post",
        url: apiURL + "_run_/town_dialogs",
        headers: headers,
        data: payload,
      })
        .then(function (response) {
          let data = response.data;
          if (!data.error) {
            data = JSON.parse(data.py);

            if (data.status)
              self.runTooltip(
                "success",
                "Uma mensagem foi enviada para o seu whatsapp!"
              );
          }
        })
        .catch((error) => {
          console.error(error);
          self.runTooltip("default_error");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    runTooltip: function (msgType, msg = null) {
      if (msgType == "default_error") {
        this.tooltipType = "error";
        this.tooltipMsg =
          "Erro ao enviar mensagem! Tente novamente mais tarde.";
      } else {
        this.tooltipType = msgType;
        this.tooltipMsg = msg;
      }
      this.showTooltip = true;
    },
  },
  computed: {
    menuItems: function () {
      let items = [
        {
          label: "Home",
          icon: "mdi-home",
          active: false,
          link: "/ebs-prefeitura/ebs-main",
        },
        {
          label: "Antecipa",
          icon: "mdi-cash",
          active: false,
          link: "/ebs-prefeitura/ebs-lend",
          accountType: "employee",
        },
        {
          label: "Extrato",
          icon: "list",
          active: false,
          link: "/ebs-prefeitura/ebs-transactions",
        },
        {
          label: "Lojas",
          icon: "storefront",
          active: false,
          link: "/ebs-prefeitura/ebs-locations",
        },
        {
          label: "Cobrar",
          icon: "shopping_cart",
          active: false,
          link: "/ebs-prefeitura/ebs-charge",
          accountType: "store",
        },
        {
          label: "Ajuda",
          icon: "mdi-help-circle-outline",
          active: false,
          dialog: "6397c1d7f1c38c9eeb45f7d0",
          accountType: "store",
        },
        {
          label: "Pagar",
          icon: "shopping_cart",
          active: false,
          link: "/ebs-prefeitura/ebs-payment",
          accountType: "employee",
        },
        {
          label: "Ajuda",
          icon: "mdi-help-circle-outline",
          active: false,
          dialog: "639b979ca36a15152812f909",
          accountType: "employee",
        },
      ];

      let result = [];
      items.forEach((item) => {
        if (item.link == this.$route.path) item.active = true;
        if (!("accountType" in item) || this.accountType == item.accountType)
          result.push(item);
      });
      return result;
    },
  },
};
</script>
