<template>
  <v-app>
    <top-menu :menuItems="topMenu" />

    <v-main>
      <v-card elevation="0" class="ma-3">
        <v-card-title class="text-h5 font-weight-medium">
          Antecipar Pagamento
        </v-card-title>
        <v-card-text class="text-body-2"
          >Você tem <span class="text-subtitle-2">R$ 1.000,00</span> disponíveis
          para antecipar</v-card-text
        >
        <v-card>
          <v-card-text class="pt-2 pb-1">
            <div class="mb-2 text-subtitle-2">
              Digite o valor que deseja antecipar:
            </div>
            <v-currency-field
              v-model="amount"
              color="black"
              outlined
              locale="pt-BR"
              prefix="R$ "
              :decimal-length="2"
              hide-details
              :rules="amountRules"
            >
            </v-currency-field>

            <div class="mt-4 mb-2 text-subtitle-2">
              Selecione em quantas vezes deseja efetuar o desconto na folha:
            </div>
            <v-radio-group v-model="installments" hide-details>
              <v-radio
                color="black"
                v-for="item in installmentsOptions"
                :key="item"
                :label="item"
                :value="item"
              ></v-radio>
            </v-radio-group>

            <div class="mt-4 mb-2 text-subtitle-2">
              Selecione como deseja receber o valor:
            </div>
            <v-select
              color="black"
              item-color="black"
              v-model="transfer"
              type="text"
              :items="transferOptions"
              outlined
              hide-details
            ></v-select>

            <v-expand-transition>
              <div v-if="transfer == 'Conta EBS'">
                <div class="mt-4 mb-2 text-subtitle-2">
                  Digite o número da sua conta EBS:
                </div>
                <v-text-field
                  color="black"
                  outlined
                  hide-details
                ></v-text-field>
              </div>
            </v-expand-transition>

            <v-expand-transition>
              <div v-if="transfer == 'PIX'">
                <div class="mt-4 mb-2 text-subtitle-2">
                  Selecione o tipo da chave PIX:
                </div>
                <v-select
                  color="black"
                  item-color="black"
                  v-model="pixKey"
                  type="text"
                  :items="pixKeyOptions"
                  outlined
                  hide-details
                ></v-select>

                <div class="mt-4 mb-2 text-subtitle-2">
                  Digite a chave PIX:
                </div>
                <v-text-field
                  color="black"
                  outlined
                  hide-details
                ></v-text-field>
              </div>
            </v-expand-transition>
          </v-card-text>

          <v-card-actions class="mt-2">
            <v-btn small class="ma-2 white--text" color="black" elevation="0"
              >Requisitar</v-btn
            >
          </v-card-actions>
        </v-card>
        <!-- <v-card class="mt-3">
        </v-card> -->
      </v-card>
    </v-main>

    <bottom-menu :account-type="accountType" />
  </v-app>
</template>

<script>
import axios from "axios";
import { apiURL } from "../../main";
import TopMenu from "../menus/top-menu";
import BottomMenu from "../menus/bottom-menu";

export default {
  components: {
    TopMenu,
    BottomMenu,
  },
  data() {
    return {
      userToken: "",
      transactions: [],
      accountType: "employee",
      topMenu: {
        title: "",
      },
      amount: null,
      amountRules: [
        (v) => !!v || "Valor obrigatório",
        (v) => (v && v != "0,00") || "Valor obrigatório",
      ],
      installments: "3x de R$ 333,00",
      installmentsOptions: [
        "3x de R$ 333,00",
        "2x de R$ 500,00",
        "1x de R$ 1.000,00",
      ],
      transfer: "Carteira Digital",
      transferOptions: ["Carteira Digital", "Conta EBS", "PIX"],
      pixKey: "documento",
      pixKeyOptions: [
        "documento",
        "celular",
        "e-mail",
        "aleatório",
        "agencia/conta",
      ],
    };
  },
  methods: {
    getPayments: function () {
      let headers = {
        Authorization: "Bearer " + this.userToken,
        "content-type": "application/json",
      };

      let url = apiURL + "user_transactions";
      if (this.accountType == "store") url = apiURL + "merchant_transactions";

      let self = this;
      axios({
        method: "get",
        url: url,
        headers: headers,
      })
        .then(function (response) {
          if (response.data.error) {
            console.log(response.data.message);
          } else {
            let data = [];
            if (self.accountType == "store")
              data = response.data.merchant_transactions;
            else data = response.data.user_transactions;

            data.map((item) => {
              item["color"] = item.aproved == "1" ? "green" : "red";
              item.date = new Date(item.date).toLocaleString("pt-BR", {
                timeZone: "America/Sao_Paulo",
              });
              return item;
            });
            self.transactions = data.slice(0, 30);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    refresh: function () {
      this.userToken = localStorage.getItem("user-token") || "";
      this.topMenu.title = localStorage.getItem("system-name") || "";
      this.accountType = localStorage.getItem("account-type");

      this.getPayments();
    },
  },
  watch: {
    $route() {
      this.refresh();
    },
  },
  mounted() {
    this.refresh();
  },
};
</script>
