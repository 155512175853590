<template>
  <v-app-bar app fixed :color="layout ? layout.topBarColor : 'black'" dark flat>
    <v-app-bar-title class="title">{{ menuItems.title }}</v-app-bar-title>
    <v-spacer></v-spacer>
    <v-btn v-if="this.userToken" @click="logout()" icon>
      <v-icon>logout</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
import axios from "axios";
import { apiURL } from "../../main";

export default {
  props: {
    menuItems: Object,
  },
  data() {
    return {
      userToken: "",
      layout: {},
    };
  },
  methods: {
    logout: function () {
      let loginMerchant = localStorage.getItem("login-merchant");
      if (!this.userToken) self.$router.push("/login/" + loginMerchant);

      let headers = {
        Authorization: "Bearer " + this.userToken,
        "content-type": "application/json",
      };

      let self = this;
      axios({
        method: "get",
        url: apiURL + "logout",
        headers: headers,
      })
        .then(function (response) {
          if (response.data.error == 1) {
            console.log(response.data.message);
            alert(response.data.message);
          } else {
            localStorage.setItem("user-token", "");
            self.$router.push("/login/" + loginMerchant);
          }
        })
        .catch((error) => {
          console.log(error);
          alert(error);
        });
    },
    refresh: function () {
      this.userToken = localStorage.getItem("user-token") || "";
      
      let layout = localStorage.getItem("layout-info");
      this.layout = layout ? JSON.parse(layout) : {};
    },
  },
  watch: {
    $route() {
      this.refresh();
    },
  },
  mounted() {
    this.refresh();
  },
};
</script>
