<template>
  <v-app :style="{ background: layout.backgroundColor }">
    <v-content>
      <alert-banner :data="alert" />
      <loading :loading="loading" />

      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-card
              class="elevation-12"
              :dark="layout.login ? layout.login.cardDark : false"
              :color="layout.login ? layout.login.cardColor : 'white'"
            >
              <v-container pb-0 v-if="!layout.hideLogo">
                <v-img
                  class="mb-2"
                  height="100px"
                  contain
                  :src="merchantInfo.logo"
                ></v-img>
              </v-container>
              <v-toolbar :dark="layout.topBarDark" :color="layout.topBarColor">
                <v-toolbar-title>{{ merchantInfo.name }}</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-text-field
                    :color="layout.topBarColor"
                    prepend-icon="person"
                    :label="
                      checkForProperty('userLabel')
                        ? layout.login.userLabel
                        : 'login'
                    "
                    type="text"
                    v-model="current.username"
                    autocapitalize="off"
                    ref="userField"
                    @keyup.enter="handleEnterKey('user')"
                  ></v-text-field>
                  <v-text-field
                    :color="layout.topBarColor"
                    prepend-icon="lock"
                    :label="
                      checkForProperty('pwdLabel')
                        ? layout.login.pwdLabel
                        : 'password'
                    "
                    type="password"
                    v-model="current.password"
                    :autocomplete="autocomplete"
                    autocorrect="off"
                    autocapitalize="off"
                    ref="passField"
                    @keyup.enter="handleEnterKey('pass')"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions
                v-if="
                  checkIfEnabled('remindPwd') || checkIfEnabled('forgotPwd')
                "
                class="pb-3 pt-0"
              >
                <v-checkbox
                  v-if="checkIfEnabled('remindPwd')"
                  class="mt-0 pt-0"
                  dense
                  true-value="on"
                  false-value="off"
                  :label="layout.login.remindPwd.label"
                  :color="layout.topBarColor"
                  v-model="autocomplete"
                  hide-details
                ></v-checkbox>
                <v-spacer></v-spacer>
                <v-btn
                  v-if="checkIfEnabled('forgotPwd')"
                  style="text-transform: none"
                  small
                  text
                  :to="layout.login.forgotPwd.link"
                  >{{ layout.login.forgotPwd.label }}</v-btn
                >
              </v-card-actions>
              <v-card-actions class="py-1">
                <v-btn
                  block
                  dark
                  :rounded="
                    checkForProperty('loginButton')
                      ? layout.login.loginButton.rounded
                      : false
                  "
                  :color="layout.topBarColor"
                  @click.native="loginButton()"
                >
                  {{
                    checkForProperty("loginButton")
                      ? layout.login.loginButton.label
                      : "Login"
                  }}
                </v-btn>
              </v-card-actions>
              <div v-if="checkForProperty('buttons')">
                <template v-for="item in layout.login.buttons">
                  <v-card-actions :key="item.index" class="pt-3">
                    <v-btn
                      block
                      dark
                      :rounded="item.rounded"
                      :color="item.color"
                      :to="item.link"
                    >
                      <v-icon v-if="item.icon" left>
                        {{ item.icon }}
                      </v-icon>
                      {{ item.label }}
                    </v-btn>
                  </v-card-actions>
                </template>
              </div>

              <v-divider
                v-if="equip != 'undefined' || checkIfEnabled('register')"
              ></v-divider>
              <v-card-actions>
                <small
                  class="pt-0"
                  v-if="equip != 'undefined'"
                  style="color: gray"
                >
                  {{ "Device: " + equip }}
                </small>
                <v-spacer></v-spacer>
                <v-btn
                  v-if="checkIfEnabled('register')"
                  style="text-transform: none"
                  small
                  text
                  :color="layout.tobBarColor"
                  :to="layout.login.register.link"
                  >{{ layout.login.register.label }}</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
        <v-dialog v-model="tinyTokenDialog" max-width="400px" persistent>
          <v-card>
            <v-card-title>
              {{
                checkForProperty("tokenLabel")
                  ? layout.login.tokenLabel
                  : "Token SMS"
              }}
            </v-card-title>
            <v-card-text>
              <v-text-field
                :color="layout.topBarColor"
                v-model="current.token"
                ref="tokenField"
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                block
                rounded
                dark
                :color="layout.topBarColor"
                @click.native="login()"
              >
                {{
                  checkForProperty("tokenButtonLabel")
                    ? layout.login.tokenButtonLabel
                    : "Logar"
                }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
    </v-content>
    <v-footer padless>
      <v-col class="text-center text-caption py-1" cols="12">
        {{ appVersion }} — EBS Bank
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import axios from "axios";
import { apiURL } from "../main";
import { v4 as uuidv4 } from "uuid";

import AlertBanner from "./global/alert";
import Loading from "./global/loading";

export default {
  components: {
    AlertBanner,
    Loading,
  },
  data: () => ({
    drawer: null,
    token: null,
    apiError: false,
    loading: false,
    errorMsg: "",
    current: {
      username: "",
      password: "",
      token: "",
    },
    alert: {
      enable: false,
    },
    account: null,
    merchant: null,
    equip: null,
    merchantInfo: {},
    layout: {},
    autocomplete: "on",
    tinyTokenDialog: false,
    deviceId: "",
    appVersion: "",
  }),
  watch: {
    autocomplete: function (val) {
      localStorage.setItem("autocomplete", val);
    },
  },
  methods: {
    handleEnterKey: function (step) {
      if (step == "user") this.$nextTick(this.$refs.passField.focus);
      else if (step == "pass") this.loginButton();
    },
    errorAlert: function (message) {
      let lockedMsg = this.layout["lockedMsg"]
        ? this.layout["lockedMsg"]
        : "423: Locked user";
      let deniedMsg = this.layout["deniedMsg"]
        ? this.layout["deniedMsg"]
        : "401: Access denied";

      this.alert = {
        enable: true,
        color: "error",
        icon: "warning",
        dismissible: true,
        message: message,
        lockedMsg: lockedMsg,
        deniedMsg: deniedMsg,
      };
    },
    checkIfEnabled: function (property) {
      if (
        this.layout &&
        this.layout.login &&
        this.layout.login[property].enabled
      )
        return true;
      else return false;
    },
    checkForProperty: function (property) {
      if (this.layout && this.layout.login && this.layout.login[property])
        return true;
      else return false;
    },
    loginButton: function () {
      if (this.layout.login && this.layout.login["tinyToken"]) this.login(true);
      else this.login();
    },
    login: function (preLogin = false) {
      this.loading = true;

      var put_data = Object.assign(
        {},
        {
          user_agent: navigator.userAgent,
          device_id: this.deviceId,
          lat: localStorage.getItem("lat"),
          long: localStorage.getItem("long"),
        },
        this.current
      );

      var put_config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      let url = apiURL + "login/" + this.merchant;
      if (preLogin) url = apiURL + "pre_login/" + this.account;

      axios
        .put(url, put_data, put_config)
        .then((response) => {
          if ("error" in response.data) this.errorAlert(response.data.message);
          else if (preLogin) {
            if (response.data.token_required) this.tinyTokenDialog = true;
            else this.login();
          } else {
            this.token = response.data.access_token;
            let userInfo = this.parseJwt(this.token)["sub"];
            let variables = JSON.parse(response.data.variables);

            localStorage.setItem("user-token", this.token);
            localStorage.setItem("user-account", userInfo.account);
            localStorage.setItem("user-login", this.current.username);
            localStorage.setItem("user-id", userInfo.id_user);

            if (variables.gmapskey)
              localStorage.setItem("gmaps-key", variables.gmapskey);

            this.$router.push(this.merchantInfo.main_view);
          }
        })
        .catch((error) => {
          localStorage.removeItem("user-token");
          console.log(error);
          this.errorAlert(error);
        })
        .finally(() => {
          if (!preLogin && this.tinyTokenDialog) {
            this.tinyTokenDialog = false;
            this.current.token = "";
          }
          this.loading = false;
        });
    },
    parseJwt: function (token) {
      var base64Url = token.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      return JSON.parse(jsonPayload);
    },
    loadData: function () {
      axios
        .get(apiURL + "merchant/" + this.merchant)
        .then((response) => {
          if ("error" in response.data && response.data.error == 1)
            this.errorAlert(response.data.message);
          else {
            this.merchantInfo = response.data.merchants[0];
            this.$vuetify.theme.dark = this.layout.dark;
            this.account = this.merchantInfo.id_merchant;

            if (
              localStorage.getItem("layout-info") !=
                String(this.merchantInfo.layout_info) ||
              localStorage.getItem("system-name") !=
                String(this.merchantInfo.system_name) ||
              localStorage.getItem("logo") != String(this.merchantInfo.logo) ||
              localStorage.getItem("tracking-interval") !=
                String(this.merchantInfo.tracking_interval * 1000) ||
              localStorage.getItem("main-view") !=
                String(this.merchantInfo.main_view)
            ) {
              localStorage.setItem(
                "layout-info",
                this.merchantInfo.layout_info
              );
              localStorage.setItem(
                "system-name",
                this.merchantInfo.system_name
              );
              localStorage.setItem(
                "tracking-interval",
                this.merchantInfo.tracking_interval * 1000
              );
              localStorage.setItem("logo", this.merchantInfo.logo);
              localStorage.setItem("main-view", this.merchantInfo.main_view);

              window.location.reload();
            }

            document.title = this.merchantInfo.system_name;
          }
        })
        .catch((error) => {
          console.log(error);
          this.errorAlert(error);
        });
    },
    getPosition: function () {
      function callback(position) {
        const lat = localStorage.getItem("lat");
        const long = localStorage.getItem("long");

        if (
          lat != position.coords.latitude ||
          long != position.coords.longitude
        ) {
          localStorage.setItem("lat", position.coords.latitude);
          localStorage.setItem("long", position.coords.longitude);
        }
      }
      navigator.geolocation.watchPosition(callback);
    },
    onDeviceReady: function () {
      /* global device */
      this.equip = device.serial;
    },
  },
  created() {
    if (Object.keys(this.$route.params).length) {
      this.merchant = decodeURIComponent(this.$route.params.account);
      this.equip = decodeURIComponent(this.$route.params.equip);
    } else this.merchant = window.location.hostname;
    localStorage.setItem("login-merchant", this.merchant);

    try {
      if (localStorage.getItem("layout-info"))
        this.layout = JSON.parse(localStorage.getItem("layout-info")) || {};
    } catch (e) {
      console.log(e);
      this.errorAlert(e);
      this.layout = {};
    }

    if (localStorage.getItem("autocomplete"))
      this.autocomplete = localStorage.getItem("autocomplete");

    if (localStorage.getItem("device-id"))
      this.deviceId = localStorage.getItem("device-id");
    else {
      this.deviceId = uuidv4();
      localStorage.setItem("device-id", this.deviceId);
    }
  },
  mounted() {
    // this.getPosition();
    this.loadData();
    this.$nextTick(this.$refs.userField.focus);

    this.appVersion = require("../../package.json").version;

    if (localStorage.getItem("registered") != "true")
      this.$router.push("/ebs-prefeitura/ebs-register?id-app=" + this.merchant);
  },
};
</script>
