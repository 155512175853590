<template>
  <v-app>
    <top-menu :menuItems="topMenu" />

    <v-main>
      <v-container>
        <v-alert
          v-model="alert.status"
          outlined
          :type="alert.type"
          text
          dismissible
          transition="scale-transition"
        >
          {{ alert.message }}
        </v-alert>
        <v-card>
          <v-card-title style="word-break: break-word">
            Solicitar autorização de pagamento
          </v-card-title>
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation :key="formReset">
              <v-text-field
                v-model="cpf"
                outlined
                color="black"
                label="CPF"
                v-mask="cpfMask"
                :rules="[cpfRules]"
                clearable
              ></v-text-field>
              <v-currency-field
                v-model="amount"
                color="black"
                outlined
                label="Valor"
                locale="pt-BR"
                prefix="R$ "
                :decimal-length="2"
                hide-details
                :rules="amountRules"
              >
              </v-currency-field>
              <v-btn class="mt-5 white--text" color="black" @click="send()"
                >enviar</v-btn
              >
            </v-form>
          </v-card-text>
        </v-card>
      </v-container>
    </v-main>

    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <bottom-menu account-type="store" />
  </v-app>
</template>

<script>
import axios from "axios";
import { apiURL } from "../../main";
import TopMenu from "../menus/top-menu";
import BottomMenu from "../menus/bottom-menu";
import { mask } from "vue-the-mask";

export default {
  components: {
    TopMenu,
    BottomMenu,
  },
  directives: {
    mask,
  },
  data() {
    return {
      userToken: "",
      userID: "",
      valid: true,
      cpf: "",
      cpfMask: "###.###.###-##",
      amount: "",
      amountRules: [
        (v) => !!v || "Valor obrigatório",
        (v) => (v && v != "0,00") || "Valor obrigatório",
      ],
      topMenu: {
        title: "",
      },
      alert: { status: false },
      loading: false,
      formReset: "1",
    };
  },
  methods: {
    send: function () {
      this.checkCPF(this.cpf);
    },
    resetForm: function () {
      this.cpf = "";
      this.amount = "";
      this.formReset = "0";
      let self = this;
      setInterval(function () {
        self.formReset = "1";
      }, 200);
    },
    sendAlert: function (status, message, type) {
      this.alert = {
        status: status,
        message: message,
        type: type,
      };
    },
    genericErrorAlert: function () {
      this.alert = {
        status: true,
        message: "Erro interno, favor tentar novamente mais tarde",
        type: "error",
      };
      this.loading = false;
    },
    cpfRules: function () {
      if (!this.cpf) return "Campo obrigatório!";
      if (this.cpf.length != 14) return "CPF inválido";
      else return true;
    },
    sendRequest: async function (data) {
      this.loading = true;

      let headers = {
        Authorization: "Bearer " + this.userToken,
        "content-type": "application/json",
      };

      let payload = {
        id_store: this.userID.toString(),
        id_employee: data.id_entidade.toString(),
        amount: this.amount.toString(),
      };

      let self = this;
      axios({
        method: "post",
        url: apiURL + "_run_/town_payment",
        headers: headers,
        data: payload,
      })
        .then(function (response) {
          if (response.data.error) console.log(response.data.message);
          else {
            response = JSON.parse(response.data.py);
            if (response.error == 0) {
              self.sendAlert(
                true,
                "Operação realizada com sucesso! Aguardando liberação pelo CPF " +
                  data.documento_cnpj_cpf,
                "success"
              );
              self.resetForm();
            } else {
              self.sendAlert(
                true,
                "erro de operação, informe ao CPF " +
                  data.documento_cnpj_cpf +
                  " o código 501",
                "error"
              );
              console.error(response.message);
            }
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          self.loading = false;
        });

      // axios({
      //   method: "post",
      //   url: apiURL + "transacoes_andamento",
      //   headers: headers,
      //   data: payload,
      // })
      //   .then(function (response) {
      //     // console.log(response);
      //     if (response.data.error) {
      //       self.genericErrorAlert();
      //       console.log(response.data.message);
      //     } else {
      //       if (response.data.transacoes_andamento[0]) {
      //         self.resetForm();
      //         self.sendAlert(
      //           true,
      // "Operação realizada com sucesso! Aguardando liberação pelo CPF " +
      //   data.documento_cnpj_cpf,
      //           "success"
      //         );
      //       }
      //     }
      //   })
      //   .catch((error) => {
      //     self.genericErrorAlert();
      //     console.log(error);
      //   })
      //   .finally(() => {
      //     self.loading = false;
      //   });
    },
    checkCPF: async function (document) {
      await this.$refs.form.validate();
      if (!this.valid) return;

      this.loading = true;

      document = document.replace(/\D/g, "");
      if (document.length != 11) return;

      let headers = {
        Authorization: "Bearer " + this.userToken,
        "content-type": "application/json",
      };

      let self = this;
      axios({
        method: "get",
        url: apiURL + "check_cpf?documento_cnpj_cpf=" + document,
        headers: headers,
      })
        .then(function (response) {
          if (response.data.error) {
            console.error(response.data.message);
            self.genericErrorAlert();
            self.loading = false;
          } else {
            if (response.data.check_cpf) {
              self.$refs.form.validate();
              self.sendRequest(response.data.check_cpf[0]);
            } else {
              self.loading = false;
              self.sendAlert(
                true,
                "o CPF informado não foi encontrado!",
                "error"
              );
            }
          }
        })
        .catch((error) => {
          self.loading = false;
          self.genericErrorAlert();
          console.error(error);
        });
    },
    refresh: function () {
      this.topMenu.title = localStorage.getItem("system-name") || "";
      this.userToken = localStorage.getItem("user-token") || "";
      this.userID = localStorage.getItem("user-id") || "";
      this.alert.status = false;
    },
  },
  watch: {
    $route() {
      this.refresh();
    },
  },
  mounted() {
    this.refresh();
  },
};
</script>
