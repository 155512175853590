<template>
  <v-dialog v-model="loading" hide-overlay persistent width="200">
    <v-card color="grey darken-3" dark>
      <v-card-text>
        Loading...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    loading: Boolean,
  },
};
</script>
