<template>
  <v-app>
    <top-menu :menuItems="topMenu" />

    <v-main>
      <GmapMap
        v-if="center"
        :center="center"
        :zoom="15"
        style="width: 100%; height: 100%"
      >
        <GmapMarker
          v-for="(marker, index) in markers"
          :key="index"
          :position="marker.position"
          :icon="marker.icon"
          @click="markerClick(marker)"
        >
          <gmap-info-window
            :options="{
              maxWidth: 300,
              pixelOffset: { width: 0, height: -10 },
            }"
            :opened="marker.infoWindow"
          >
            <p class="ma-0 text-body-2">{{ marker.data.categoria }}</p>
            <p class="ma-0 text-subtitle-2">{{ marker.data.nome }}</p>
            <p class="ma-0 text-caption">{{ marker.data.celular }}</p>
          </gmap-info-window>
        </GmapMarker>
      </GmapMap>
    </v-main>

    <bottom-menu :account-type="accountType" />
  </v-app>
</template>

<script>
import { loadGmapApi, gmapApi } from "vue2-google-maps";
import axios from "axios";
import { apiURL } from "../main";
import TopMenu from "./menus/top-menu";
import BottomMenu from "./menus/bottom-menu";

export default {
  components: {
    TopMenu,
    BottomMenu,
  },
  data() {
    return {
      accountType: "employee",
      center: null,
      currentPlace: null,
      markers: [],
      geocoder: null,
      userToken: "",
      gmapsKey: "",
      sheet: true,
      topMenu: {
        title: "Pontos de venda",
      },
    };
  },
  computed: {
    google: gmapApi,
  },
  methods: {
    markerClick: function (item) {
      item.infoWindow = !item.infoWindow;
    },
    // geolocate: function () {
    //   navigator.geolocation.getCurrentPosition(
    //     (position) => {
    //       this.center = {
    //         lat: position.coords.latitude,
    //         lng: position.coords.longitude,
    //       };
    //       this.addMarker(this.center);
    //     },
    //     function errorHandler(error) {
    //       console.log(error);
    //     },
    //     { enableHighAccuracy: true, timeout: 20000, maximumAge: 15000 }
    //   );
    // },
    getLocation: async function (address, data = null) {
      try {
        await this.geocoder.geocode(
          {
            address: address,
          },
          (results, status) => {
            if (status == "OK") this.addMarker(results[0], data);
          }
        );
      } catch (error) {
        console.error(`${error} (${address})`);
      }

      return new Promise((resolve) => setTimeout(resolve, 200));
    },
    addMarker(place, data = null) {
      let location = place;

      let icon = {
        url: require("../assets/star.png"),
      };

      if ("geometry" in place) {
        location = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };
      }
      icon = {
        url: require("../assets/mall.png"),
      };

      this.center = location;

      let marker = {
        position: location,
        infoWindow: false,
        icon: icon,
        data: data ? data : {},
      };

      if (!this.markers.includes(marker)) this.markers.push(marker);
    },
    loadPlaces: function () {
      let headers = {
        Authorization: "Bearer " + this.userToken,
        "content-type": "application/json",
      };

      let self = this;
      axios({
        method: "get",
        url: apiURL + "stores",
        headers: headers,
      })
        .then(async function (response) {
          if (response.data.error) console.log(response.data.message);
          else {
            let data = response.data.stores;

            for (const item of data) {
              if (item.lat != 0) {
                self.addMarker(
                  {
                    lat: item.lat,
                    lng: item.lng,
                  },
                  item
                );
              } else {
                let address =
                  item.endereco + " - " + item.cidade + " " + item.estado;
                await self.getLocation(address, item);
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    refresh: async function () {
      this.mapItems = [];
      this.topMenu.title = localStorage.getItem("system-name") || "";
      this.userToken = localStorage.getItem("user-token") || "";
      this.gmapsKey = localStorage.getItem("gmaps-key") || "";
      this.accountType = localStorage.getItem("account-type");

      let self = this;

      let apiInterval = setInterval(function () {
        if (self.gmapsKey && !self.google) {
          loadGmapApi({
            key: self.gmapsKey,
            libraries: "places",
          });
          clearInterval(apiInterval);
        }
      }, 500);

      let geocoderInterval = setInterval(function () {
        if (self.google) {
          self.geocoder = new self.google.maps.Geocoder();
          clearInterval(geocoderInterval);
        }
      }, 500);

      let placesInterval = setInterval(function () {
        if (self.geocoder) {
          if (self.userToken) self.loadPlaces();

          // if (self.userToken) self.geolocate();
          clearInterval(placesInterval);
        }
      }, 500);
    },
  },
  // watch: {
  //   $route() {
  //     this.refresh();
  //   },
  // },
  mounted() {
    this.refresh();
  },
};
</script>
