<template>
  <v-container pa-0 ma-0 fluid>
    <v-alert
      v-model="alert_enable"
      :color="data.color"
      :icon="data.icon"
      :dismissible="data.dismissible"
    >
      {{ message }}
    </v-alert>
    <v-dialog
      :value="dialog_enable"
      max-width="600"
      persistent
      overlay-opacity="0.95"
    >
      <!-- <template v-slot:default="dialog"> -->
      <v-card>
        <v-card-text>
          <div class="text-h6 pa-2">
            Parece que houve um problema com a sua conexão!
          </div>
        </v-card-text>
        <v-card-actions class="justify-center pb-4">
          <v-btn color="blue" dark @click="refresh()">Atualizar</v-btn>
        </v-card-actions>
      </v-card>
      <!-- </template> -->
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  props: {
    data: Object,
  },
  computed: {
    alert_enable: function () {
      if (
        this.data.enable &&
        !String(this.data.message).includes(
          "Network Error"
          // '401'
        )
      )
        return true;
      else return false;
    },
    dialog_enable: function () {
      if (
        this.data.enable &&
        String(this.data.message).includes(
          "Network Error"
          // '401'
        )
      )
        return true;
      else return false;
    },
    message: function () {
      if (
        this.data.enable &&
        this.data["lockedMsg"] &&
        String(this.data.message).includes("code 423")
      )
        return this.data.lockedMsg;
      if (
        this.data.enable &&
        this.data["deniedMsg"] &&
        String(this.data.message).includes("code 401")
      )
        return this.data.deniedMsg;
      else return this.data.message;
    },
  },
  methods: {
    refresh: function () {
      window.location.reload();
    },
  },
};
</script>
