<template>
  <v-container pa-0 fluid>
    <top-menu :menuItems="topMenu" />

    <v-main>
      <!-- CLIENT DASHBOARD START -->
      <template v-if="accountType == 'employee'">
        <v-carousel cycle height="200" hide-delimiters>
          <v-carousel-item
            v-for="(slide, i) in slides"
            :key="i"
            :src="slide.image"
          ></v-carousel-item>
        </v-carousel>

        <v-card class="ma-3">
          <div class="pt-3 px-4 text-h5">{{ cpf }}</div>
          <v-card-text class="pt-2 pb-1">
            <div class="mt-3 text-subtitle-1">Saldo</div>
            <v-list-item class="pl-0">
              <div class="text-h6">
                {{ showBalance ? currency : "••••••" }}
              </div>
              <v-btn icon class="ml-3 mb-1" @click="showBalance = !showBalance">
                <v-icon>{{
                  showBalance ? "visibility" : "visibility_off"
                }}</v-icon>
              </v-btn>
            </v-list-item>
          </v-card-text>
          <v-card-actions class="pt-0">
            <v-btn
              small
              class="ma-2 white--text"
              color="black"
              elevation="0"
              to="/ebs-prefeitura/ebs-transactions"
              >ver extrato</v-btn
            >
          </v-card-actions>
        </v-card>

        <v-card class="ma-3">
          <v-timeline align-top dense v-if="transactions.length">
            <v-timeline-item
              small
              v-for="(item, i) in transactions"
              :key="i"
              :color="item.color"
            >
              <v-row class="pt-1" @click="timelineLink(item)">
                <v-col cols="4">
                  <strong>R$ {{ item.value }}</strong>
                </v-col>
                <v-col>
                  <strong>{{ item.name }}</strong>
                  <div class="text-caption">{{ item.date }}</div>
                </v-col>
              </v-row>
            </v-timeline-item>
          </v-timeline>
        </v-card>
      </template>
      <!-- CLIENT DASHBOARD END -->

      <!-- MERCHANT DASHBOARD START -->
      <template v-if="accountType == 'store'">
        <v-card class="ma-3 text-center" color="green" dark>
          <v-card-text>
            <v-sheet color="rgba(0, 0, 0, .12)">
              <v-sparkline
                :value="chartData"
                color="rgba(255, 255, 255, .7)"
                height="100"
                padding="24"
                stroke-linecap="round"
                smooth
              >
                <template v-slot:label="item"> ${{ item.value }} </template>
              </v-sparkline>
            </v-sheet>
          </v-card-text>

          <v-card-text class="pt-0 pb-3">
            <div class="text-h4 font-weight-thin">Vendas 24h</div>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions class="justify-center">
            <v-btn disabled text>Dia</v-btn>
            <v-btn text>Mês</v-btn>
            <v-btn text>Ano</v-btn>
          </v-card-actions>
        </v-card>

        <v-card class="ma-3 pa-3">
          <v-card-title>Últimas vendas</v-card-title>
          <v-card
            class="mx-4 mb-3"
            v-if="!transactions.length"
            flat
            color="grey lighten-4"
            height="auto"
          >
            <v-card-text class="text-h5 text-center grey--text">
              Histórico vazio
            </v-card-text>
          </v-card>
          <v-timeline align-top dense v-if="transactions.length">
            <v-timeline-item
              small
              v-for="(item, i) in transactions"
              :key="i"
              :color="item.color"
            >
              <v-row class="pt-1" @click="timelineLink(item)">
                <v-col cols="4">
                  <strong>R$ {{ item.value }}</strong>
                </v-col>
                <v-col>
                  <strong>{{ item.name }}</strong>
                  <div class="text-caption">{{ item.date }}</div>
                </v-col>
              </v-row>
            </v-timeline-item>
          </v-timeline>
        </v-card>
      </template>
      <!-- MERCHANT DASHBOARD END -->
    </v-main>

    <bottom-menu :account-type="accountType" />
  </v-container>
</template>

<script>
import axios from "axios";
import { apiURL } from "../main";
import TopMenu from "./menus/top-menu";
import BottomMenu from "./menus/bottom-menu";

export default {
  components: {
    TopMenu,
    BottomMenu,
  },
  data() {
    return {
      userToken: "",
      colors: [
        "indigo",
        "warning",
        "pink darken-2",
        "red lighten-1",
        "deep-purple accent-4",
      ],
      slides: [],
      transactions: [],
      accountType: "employee",
      cpf: "000.000.000-00",
      chartData: [423, 446, 675, 510, 590, 610, 760],
      topMenu: {
        title: "",
      },
      balance: "0.00",
      showBalance: false,
    };
  },
  computed: {
    currency: function () {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "brl",
      }).format(this.balance);
    },
  },
  methods: {
    timelineLink: function (item) {
      if (this.accountType == "store")
        this.$router.push("/ebs-prefeitura/ebs-transactions");
      else if (item.aproved == "1")
        this.$router.push("/ebs-prefeitura/ebs-transactions");
      else this.$router.push("/ebs-prefeitura/ebs-payment");
    },
    loadUser: function () {
      let headers = {
        Authorization: "Bearer " + this.userToken,
        "content-type": "application/json",
      };

      let self = this;
      axios({
        method: "get",
        url: apiURL + "get_user",
        headers: headers,
      })
        .then(function (response) {
          if (response.data.error) console.log(response.data.message);
          else {
            let data = response.data.get_user[0];

            if (!data.beneficiario) self.accountType = "store";
            else {
              self.accountType = "employee";
              self.balance = data.saldo_entidade;
              self.cpf = self.formatID(data.documento_cnpj_cpf);
            }
            localStorage.setItem("account-type", self.accountType);

            self.getPayments();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loadSlides: function () {
      let headers = {
        Authorization: "Bearer " + this.userToken,
        "content-type": "application/json",
      };

      let self = this;
      axios({
        method: "get",
        url: apiURL + "get_slides",
        headers: headers,
      })
        .then(function (response) {
          if (response.data.error) console.log(response.data.message);
          else self.slides = response.data.get_slides;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    formatID: function (document) {
      switch (document.length) {
        case 11:
          document = document.match(/.{2,3}/g).join(".");
          document = document.replace(/.([^.]*)$/, "-$1");
          break;
        case 14:
          document =
            document.substring(0, 2) +
            "." +
            document.substring(2, 5) +
            "." +
            document.substring(5, 8) +
            "/" +
            document.substring(8, 12) +
            "-" +
            document.substring(12, 14);
          break;
      }
      return document;
    },
    getPayments: function () {
      let headers = {
        Authorization: "Bearer " + this.userToken,
        "content-type": "application/json",
      };

      let url = apiURL + "user_transactions";
      if (this.accountType == "store") url = apiURL + "merchant_transactions";

      let self = this;
      axios({
        method: "get",
        url: url,
        headers: headers,
      })
        .then(function (response) {
          if (response.data.error) {
            console.log(response.data.message);
          } else {
            let data = [];
            if (self.accountType == "store")
              data = response.data.merchant_transactions;
            else data = response.data.user_transactions;

            data.map((item) => {
              item["color"] = item.aproved == "1" ? "green" : "red";
              item.date = new Date(item.date).toLocaleString("pt-BR", {
                timeZone: "America/Sao_Paulo",
              });
              return item;
            });
            // console.log(data);
            self.transactions = data.slice(0, 3);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    refresh: function () {
      this.showBalance = false;
      this.userToken = localStorage.getItem("user-token") || "";
      this.topMenu.title = localStorage.getItem("system-name") || "";
      this.loadUser();
      this.loadSlides();
    },
  },
  watch: {
    $route() {
      this.refresh();
    },
  },
  mounted() {
    this.refresh();
  },
};
</script>
